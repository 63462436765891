exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Arial', 'Segoe UI', 'HelveticaNeue-Light', sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1230px;\n  --brand-color: #61dafb;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n}\n\n._2CyZT {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 62px;\n  background: #f5f5f5;\n  color: #000;\n}\n\n._1fOXG {\n  margin: 0 auto;\n  padding: 20px 15px;\n  max-width: 1230px;\n  max-width: 1230px;\n  max-width: var(--max-content-width);\n  text-align: center;\n}\n\n.p91HP {\n  color: rgba(0, 0, 0, 0.5);\n}\n\n/*\n.spacer {\n  color: rgba(0, 0, 0, 0.3);\n} */\n\n.p91HP,\n._1m_67 {\n  margin: 2px;\n  font-size: 1em;\n  border: 0;\n}\n\n._1m_67,\n._1m_67:active,\n._1m_67:visited {\n  color: rgba(0, 0, 0, 0.5);\n  text-decoration: none;\n}\n\n._1m_67:hover {\n  color: #1264a3;\n  border-bottom: 1px solid #1264a3;\n}\n", ""]);

// exports
exports.locals = {
	"root": "_2CyZT",
	"container": "_1fOXG",
	"text": "p91HP",
	"link": "_1m_67"
};