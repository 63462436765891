import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu';
import { connect } from 'react-redux';
import Link from 'components/site/Link';
import s from './Navigation.css';

const messages = defineMessages({
  about: {
    id: 'navigation.about',
    defaultMessage: 'About',
    description: 'About link in header',
  },
  contact: {
    id: 'navigation.contact',
    defaultMessage: 'Contact',
    description: 'Contact link in header',
  },
  login: {
    id: 'navigation.login',
    defaultMessage: 'Sign in',
    description: 'Sign in link in header',
  },
  or: {
    id: 'navigation.separator.or',
    defaultMessage: 'or',
    description: 'Last separator in list, lowercase "or"',
  },
  getStarted: {
    id: 'navigation.getStarted',
    defaultMessage: 'Get Started',
    description: 'Get Started button in header',
  },
  yourWorkshops: {
    id: 'navigation.yourWorkshops',
    defaultMessage: 'Your Workshops',
    description: 'Your Workshops button in header',
  },
});

let contextTrigger = null;

class Navigation extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    loggedIn: PropTypes.bool,
    shopName: PropTypes.string,
    shopUrl: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    loggedIn: false,
    shopName: 'My Workshop',
    shopUrl: '',
  };

  handleClick = e => {
    if (contextTrigger) contextTrigger.handleContextClick(e);
  };

  handleKey = e => {
    if (e.keyCode === 13 || e.keyCode === 32) this.handleClick(e);
  };

  render() {
    const { loggedIn, className, shopName, shopUrl } = this.props;

    return (
      <div className={cx(s.root, className)} role="navigation">
        {!loggedIn && (
          <Link className={s.link} to="/signin">
            <FormattedMessage {...messages.login} />
          </Link>
        )}
        {!loggedIn && (
          <Link className={s.button} to="/get-started">
            <FormattedMessage {...messages.getStarted} />
          </Link>
        )}
        {loggedIn && (
          <div>
            <ContextMenuTrigger
              id="your-workshops-menu"
              ref={c => {
                contextTrigger = c;
              }}
            >
              <button
                type="button"
                className={s.button}
                onMouseDown={this.handleClick}
                onKeyDown={this.handleKey}
              >
                <FormattedMessage {...messages.yourWorkshops} />
              </button>
            </ContextMenuTrigger>
            <ContextMenu id="your-workshops-menu" className={s.menu}>
              <a className={s.shopItem} href={shopUrl}>
                <div className={s.shopLogo}>{shopName[0]}</div>
                <div className={s.shopName}>{shopName}</div>
              </a>
              <div className={s.menuDivider} />
              <Link className={s.menuItem} to="/signin">
                Sign Into Another Workshop
              </Link>
              <Link className={s.menuItem} to="/create">
                Create Workshop
              </Link>
            </ContextMenu>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state.auth;
  let shopName;
  let shopUrl;

  if (user && user.shop) {
    const { shop } = user;
    let subdomain;

    if (!shop.name && shop.subdomain) {
      subdomain = shop.subdomain.replace('-', ' ');
    }
    shopName = shop.name || subdomain;

    if (shop && shop.subdomain) {
      shopUrl = `https://${shop.subdomain}${
        __DEV__ ? '.dev' : ''
      }.techsbox.com`;
    }
  }

  return { loggedIn: !!(user.id && user.shopId), shopName, shopUrl };
};

export default connect(mapStateToProps)(withStyles(s)(Navigation));
