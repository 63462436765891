import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import Link from 'components/site/Link';
import s from './Logo.css';

class Logo extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    subdomain: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    subdomain: '',
  };

  render() {
    return (
      <div className={this.props.className}>
        <Link className={s.brand} to={this.props.subdomain ? '/s/' : '/'}>
          <span className={s.logo} />
          <span className={s.text}>Techsbox</span>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  subdomain: state.shop.subdomain,
});

export default connect(
  mapStateToProps,
  {},
)(withStyles(s)(Logo));
