import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import { getStarted } from 'actions/auth';
import { isEmailValid } from 'utils';
import Link from 'components/site/Link';
import s from './Home.css';

let timer;
const handleFocus = () => clearTimeout(timer);

class Home extends React.Component {
  static propTypes = {
    getStarted: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: null,
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleBlur() {
    timer = setTimeout(() => {
      this.setState({ error: '' });
    }, 128);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { email } = this.state;
    const emailAddress = email.trim().toLowerCase();

    if (isEmailValid(emailAddress)) {
      this.props.getStarted(emailAddress);
    } else {
      let error = 'Sorry, but that email is invalid.';
      if (email.length === 0) error = 'Please fill out your email address!';
      this.setState({ error });
    }
  }

  render() {
    const { error } = this.state;
    return (
      <div className={s.root}>
        <div className={s.container}>
          <h1 className={s.title}>Manage your repair business on Techsbox</h1>
          <p className={s.subtitle}>
            Techsbox helps run your business so you can focus on providing great
            service.
          </p>
          <form method="post" onSubmit={this.handleSubmit} noValidate>
            <div className={s.formGroup}>
              <div className={s.tooltipWrapper}>
                <input
                  className={s.input}
                  id="email"
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  placeholder="Your work email"
                  onFocus={handleFocus}
                  onBlur={this.handleBlur}
                />
                {error && <div className={s.tooltip}>{error}</div>}
              </div>
              <button
                className={s.button}
                type="submit"
                onFocus={handleFocus}
                onBlur={this.handleBlur}
              >
                Try For Free
              </button>
            </div>
          </form>
          <p className={s.signin}>
            Already using Techsbox?&nbsp;
            <Link className={s.link} to="/signin">
              Sign in
            </Link>
            .
          </p>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { getStarted },
)(withStyles(s)(Home));
