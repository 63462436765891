import validator from 'validator';
import ReservedSubdomains from 'reserved-subdomains';

// eslint-disable-next-line no-control-regex
const emailsMatcher = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g; // emailregex.com
const validUsername = /^[0-9A-Z_a-z]{1,15}$/;
const validPassword = /^\S[0-9A-Za-z {}()[\]#:;^,.?!|&_`~@$%/\\=+\-*"']{6,125}[^.\s]$/;
const spacePassword = /^\s.*|.*\s$/; // has leading or trailing space

// default client side email validation to prevent typos and bounced emails
export function isEmailValid(email) {
  return (
    email &&
    /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/.test(
      email,
    )
  );
}

// checks if reserved subdomain string (ex. blog, www, api)
export function isReservedSubdomain(subdomain) {
  return ReservedSubdomains.isNotValid(subdomain);
}

// creates slugged subdomain use safe slug from string
export function sanitizeSubdomain(value, allowTrailing) {
  return (
    value
      // remove starting periods
      .replace(/^\.+/, '')
      // remove trailing periods
      .replace(/\.+$/, '')
      // replace periods with hyphens
      .replace(/\./g, '-')
      // replace spaces hyphens
      .replace(/ /g, '-')
      // remove invalid special characters
      .replace(/[^-\w\s]/gi, '')
      // trim whitespace of the ends
      .trim()
      // force lowercase characters
      .toLowerCase()
      // remove starting hyphens
      .replace(/^-+/g, '')
      // remove trailing hyphens
      .replace(allowTrailing ? '' : /-+$/g, '')
  );
}

export function getUsernameErrors(username) {
  const errors = [];

  if (!username) {
    errors.push({
      key: 'username',
      message: 'A username is required.',
    });
  }

  if (username.length > 15) {
    errors.push({
      key: 'username',
      message: 'Your username cannot be longer than 15 characters.',
    });
  }

  if (!validUsername.test(username)) {
    errors.push({
      key: 'username',
      message:
        'A username can only contain alphanumeric and underscore characters.',
    });
  }

  return errors;
}

export function getEmailAddErrors(email) {
  const errors = [];

  if (!email || !validator.isEmail(email)) {
    errors.push({
      key: 'email',
      message: 'A valid email address is required.',
    });
  }

  return errors;
}

export function getPasswordErrors(password) {
  const errors = [];

  if (password.length < 8) {
    errors.push({
      key: 'password',
      type: 'length',
      message: 'Password must be at least 8 characters long',
    });
  }

  if (password.length > 127) {
    errors.push({
      key: 'password',
      type: 'length',
      message: 'Password must be less than 127 characters long',
    });
  }

  if (spacePassword.test(password)) {
    errors.push({
      key: 'password',
      type: 'characters',
      message: 'Password must not start or end with spaces.',
    });
  }

  if (!validPassword.test(password)) {
    errors.push({
      key: 'password',
      type: 'characters',
      message:
        'Password can only contain alphanumeric and the these special characters: {}()[]#:;^,.?!|&_`~@$%/\\=+-*"\'',
    });
  }

  return errors;
}

export function getEmailsFromString(string) {
  const emails = string.match(emailsMatcher);
  return emails || [];
}

// constructor for multi-middleware route objects
export function Route(route) {
  const { middleware } = route;
  let retRoute = route;
  delete retRoute.middleware;

  if (middleware) {
    if (Array.isArray(middleware)) {
      middleware.forEach(action => {
        retRoute = {
          path: retRoute.path,
          children: [
            {
              ...retRoute,
              path: '',
            },
          ],
          action,
        };
      });
    } else {
      retRoute = route;
      retRoute.action = middleware;
    }
  }

  return retRoute;
}

export function isAdminUser(role) {
  return ['superuser', 'owner', 'admin'].includes(role);
}

export function clean(obj) {
  const propNames = Object.getOwnPropertyNames(obj);
  for (let i = 0; i < propNames.length; i += 1) {
    const propName = propNames[i];
    if (
      obj[propName] === '' ||
      obj[propName] === null ||
      obj[propName] === undefined
    ) {
      delete obj[propName]; // eslint-disable-line no-param-reassign
    }
  }
}

export function nullEmpty(obj) {
  const propNames = Object.getOwnPropertyNames(obj);
  for (let i = 0; i < propNames.length; i += 1) {
    const propName = propNames[i];
    if (obj[propName] === '' || obj[propName] === undefined) {
      obj[propName] = null; // eslint-disable-line no-param-reassign
    }
  }
}

export function toFixed(num, precision = 2) {
  return (+`${Math.round(+`${num || 0}e${precision}`)}e${-precision}`).toFixed(
    precision,
  );
}

// counts number of pages for a list of items with set pageSize limit
export function countPages(items, pageSize) {
  if (!items || !pageSize) return 0;
  const remaining = items % pageSize;
  return (items - remaining) / pageSize + (remaining ? 1 : 0);
}
