exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Arial', 'Segoe UI', 'HelveticaNeue-Light', sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1230px;\n  --brand-color: #61dafb;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n}\n\n._3JFXG {\n  background: #fff;\n  color: #000;\n}\n\n._3N9US {\n  margin: 0 auto;\n  padding: 16px 0;\n  max-width: 1230px;\n  max-width: 1230px;\n  max-width: var(--max-content-width);\n}\n\n._3y5j9 {\n  display: -ms-flexbox;\n  display: flex;\n  margin: 0;\n}\n\n@media only screen and (max-width: 1250px) {\n  ._3y5j9 {\n    margin: 0 0 0 16px;\n  }\n}\n\n@media only screen and (max-width: 360px) {\n  ._3y5j9 {\n    display: inline-block;\n  }\n\n  ._3N9US {\n    text-align: center;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"root": "_3JFXG",
	"container": "_3N9US",
	"logo": "_3y5j9"
};