import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import Link from 'components/site/Link';
import s from './Footer.css';

class Footer extends React.Component {
  static propTypes = {
    subdomain: PropTypes.string,
  };

  static defaultProps = {
    subdomain: '',
  };

  render() {
    return (
      <div className={s.root}>
        <div className={s.container}>
          <Link className={s.link} to={this.props.subdomain ? '/s/' : '/'}>
            Techsbox
          </Link>
          <span className={s.text}>© 2024</span>
          {/*
          <span className={s.spacer}>·</span>
          <Link className={s.link} to="/">
            Home
          </Link>
          <span className={s.spacer}>·</span>
          <Link className={s.link} to="/about">
            About
          </Link>
          <Link className={s.link} to="/contact">
            Contact
          </Link>
          <span className={s.spacer}>·</span>
          <Link className={s.link} to="/privacy">
            Privacy
          </Link>
          */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  subdomain: state.shop.subdomain,
});

export default connect(mapStateToProps, {})(withStyles(s)(Footer));
