exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Arial', 'Segoe UI', 'HelveticaNeue-Light', sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1230px;\n  --brand-color: #61dafb;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n}\n\n._34oCg {\n  display: -ms-flexbox;\n  display: flex;\n  color: rgb(146, 229, 252);\n  color: color(#61dafb lightness(+10%));\n  color: color(var(--brand-color) lightness(+10%));\n  text-decoration: none;\n  font-size: 1.75em; /* ~28px */\n  line-height: 38px;\n  margin: 0;\n}\n\n._1I5gx {\n  display: inline-block;\n  position: relative;\n  width: 36px;\n  height: 36px;\n  border-style: solid;\n  border-width: 2px;\n  border-color: #fff;\n  background: #000;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n}\n\n._1I5gx::after {\n  content: 'T';\n  display: inline-block;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  line-height: 32px;\n  text-align: center;\n  font-size: 10px;\n  color: #fff;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n}\n\n.VsT-A {\n  display: inline-block;\n  position: relative;\n  margin-left: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"brand": "_34oCg",
	"logo": "_1I5gx",
	"text": "VsT-A"
};