import React from 'react';
import Layout from 'components/site/Layout';
import Home from './Home';

async function action() {
  return {
    title: '',
    chunks: ['site-home'],
    component: (
      <Layout>
        <Home />
      </Layout>
    ),
  };
}

export default action;
